import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { CurrentUserProvider } from "./CurrentUser";
import { msalConfig } from "./authConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <CurrentUserProvider>
        <App />
      </CurrentUserProvider>
    </MsalProvider>
  </React.StrictMode>
);
