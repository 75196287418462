export function getPasswordRequirements(username: string): Record<string, (p: string) => boolean> {
  return {
    "be at least 8 characters long": (p: string) => p.length >= 8,
    "have an uppercase character": (p: string) => p.toLowerCase() !== p,
    "have a lowercase character": (p: string) => p.toUpperCase() !== p,
    "contain a special character (!@#$%^&*_-+=`|(){ }[]:;\"'<>,.?/) or number": (p: string) =>
      /[\d~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?/]/.test(p),
    "not be a previous password": (p: string) => true,
    "not contain your username, name or email": (p: string) => !p.includes(username),
  };
}
