import { Alert, Tab, Row, Col, Nav, Button, Form, Spinner } from "react-bootstrap";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { InteractionType } from "@azure/msal-browser";
import { Loading } from "./Loading";
import ErrorAlert from "./ErrorAlert";
import NewPasswordForm from "./NewPasswordForm";
import { useCurrentUser } from "../CurrentUser";
import { getPasswordRequirements } from "../utils/passwords";
import PasswordRequirements from "./PasswordRequirements";
import { checkError } from "../utils/apiError";
import React from "react";
import { loginRequest } from "../authConfig";

function ProfilePage() {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      loadingComponent={() => <Loading />}
      errorComponent={({ error }) => (
        <ErrorAlert msg={`Authentication error: ${error?.errorMessage} (${error?.errorCode})`} />
      )}
    >
      <ProfileBody />
    </MsalAuthenticationTemplate>
  );
}

function ProfileBody() {
  const { currentUser } = useCurrentUser();
  if (currentUser === undefined) {
    // We are probably waiting for the user profile to be fetched.
    return <Loading />;
  }
  return (
    <>
      <h1>Profile</h1>
      <Tab.Container id="left-tabs-example" defaultActiveKey="info">
        <Row>
          <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="info" href="#">
                  Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="groups" href="#">
                  Groups
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="password" href="#">
                  Change Password
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <InfoPane />
              </Tab.Pane>
              <Tab.Pane eventKey="group">
                <GroupsPane />
              </Tab.Pane>
              <Tab.Pane eventKey="password">
                <ChangePasswordPane />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}

function InfoPane() {
  const { currentUser, fetchAuth } = useCurrentUser();
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string | null>();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    fetchAuth("/api/private/profile", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ mail: email }),
    }).then((r) => {
      setSubmitting(false);
      checkError(r).then(setError);
    });
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setEmail(currentUser?.mail);
  };

  useEffect(() => {
    setEmail(currentUser?.mail);
  }, [currentUser]);

  return (
    <>
      {error === null && <Alert variant="success">Please check your email for further instructions.</Alert>}
      {error !== null && error !== undefined && <ErrorAlert msg={error} />}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" value={currentUser?.name} disabled />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Surname</Form.Label>
          <Form.Control type="text" value={currentUser?.surname} disabled />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control type="text" value={currentUser?.username} disabled />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={email === currentUser?.mail || email === ""}>
          {submitting && (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="visually-hidden">Submitting...</span>{" "}
            </>
          )}
          Save
        </Button>{" "}
        <Button variant="light" onClick={handleCancel}>
          Cancel
        </Button>
      </Form>
    </>
  );
}

function GroupsPane() {
  const { currentUser } = useCurrentUser();
  return (
    <ul>
      {currentUser?.groups.map((g: string) => (
        <li>{g}</li>
      ))}
    </ul>
  );
}

function ChangePasswordPane() {
  const { currentUser, fetchAuth } = useCurrentUser();
  const [submitting, setSubmitting] = useState(false);
  const [current, setCurrent] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [error, setError] = useState<string | null>();
  const [valid, setValid] = useState(false);

  const passwordRequirements = getPasswordRequirements(currentUser?.username || "");

  const clearForm = () => {
    setCurrent("");
    setNewPassword("");
    setConfirm("");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    fetchAuth("/api/private/change-password", {
      method: "POST",
      body: JSON.stringify({ current_password: current, new_password: newPassword }),
    }).then((r) => {
      setSubmitting(false);
      checkError(r).then(setError);
      clearForm();
    });
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    clearForm();
  };

  return (
    <>
      {error === null && <Alert variant="success">Your password has been changed successfully.</Alert>}
      {error !== null && error !== undefined && <ErrorAlert msg={error} />}
      <PasswordRequirements requirements={passwordRequirements} />
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Current Password</Form.Label>
          <Form.Control type="password" required value={current} onChange={(e) => setCurrent(e.target.value)} />
        </Form.Group>
        <NewPasswordForm
          password={newPassword}
          setPassword={setNewPassword}
          confirm={confirm}
          setConfirm={setConfirm}
          requirements={passwordRequirements}
          setValid={setValid}
        />
        <Button variant="primary" type="submit" disabled={!valid}>
          {submitting && (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="visually-hidden">Submitting...</span>{" "}
            </>
          )}
          Save
        </Button>{" "}
        <Button variant="light" onClick={handleCancel}>
          Cancel
        </Button>
      </Form>
    </>
  );
}

export default ProfilePage;
