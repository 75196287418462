import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCurrentUser } from "../CurrentUser";
import { checkError } from "../utils/apiError";
import ErrorAlert from "./ErrorAlert";
import { Loading } from "./Loading";

interface ConfirmEmailToken {
  mail: string;
}

function ConfirmEmailPage() {
  const { clearProfile } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      return;
    }
    fetch("/api/public/confirm-email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: token }),
    }).then((r: Response) => {
      checkError(r).then(setError);
      clearProfile();
    });
  }, [token, clearProfile]);

  if (!token) {
    return <ErrorAlert msg="No token provided."></ErrorAlert>;
  }

  if (error === undefined) {
    return <Loading />;
  } else if (error !== null) {
    return <ErrorAlert msg={error} />;
  }

  const decoded: ConfirmEmailToken = jwtDecode(token);

  return (
    <>
      <h1>Email Confirmed</h1>
      <p>
        Your email has been successfully changed to <strong>{decoded.mail}</strong>
      </p>
    </>
  );
}

export default ConfirmEmailPage;
