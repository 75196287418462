import React, { useState } from "react";
import { Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import NewPasswordForm from "./NewPasswordForm";
import ErrorAlert from "./ErrorAlert";
import { getPasswordRequirements } from "../utils/passwords";
import PasswordRequirements from "./PasswordRequirements";
import { checkError } from "../utils/apiError";
import ErrorPage from "./ErrorPage";

interface ResetToken {
  user: string;
}

function SetPassword(props: { token: string }) {
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();

  const { token } = props;

  let decoded: ResetToken;
  try {
    decoded = jwtDecode(token);
  } catch (e) {
    return <ErrorAlert msg="Invalid token" />;
  }

  const passwordRequirements = getPasswordRequirements(decoded.user);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    fetch("/api/public/change-password", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: token,
        password: password,
      }),
    }).then((r: Response) => {
      setSubmitting(false);
      checkError(r).then(setError);
    });
  };

  const passwordErrors = (): Array<string> => {
    const errors: Array<string> = [];
    Object.entries(passwordRequirements).forEach(([k, v]) => {
      !v(password) && errors.push(k);
    });
    return errors;
  };

  if (error === null) {
    return (
      <Alert variant="success">
        Your password was successfully changed. It may take a few minutes for changes to propogate.
        <br />
        Make sure to update your password on all your devices (E-Mail, Wi-Fi, etc).
      </Alert>
    );
  } else if (error !== undefined) {
    return <ErrorPage title="Error changing your password" detail={error} />;
  }

  return (
    <>
      <h1>Reset your password</h1>

      <PasswordRequirements requirements={passwordRequirements} />
      <Col md={4}>
        <Form onSubmit={handleSubmit}>
          <NewPasswordForm
            password={password}
            setPassword={setPassword}
            requirements={passwordRequirements}
            confirm={confirm}
            setConfirm={setConfirm}
          />
          {/* https://github.com/react-bootstrap/react-bootstrap/issues/6103 */}
          <Button variant="primary" type="submit" disabled={passwordErrors().length !== 0}>
            {submitting && (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="visually-hidden">Submitting...</span>{" "}
              </>
            )}
            Submit
          </Button>{" "}
          <Button variant="light" as={Link as any} to="/">
            Cancel
          </Button>
        </Form>
      </Col>
    </>
  );
}

export default SetPassword;
