import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockOpen, faUser, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Col, Row, Card } from "react-bootstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useCurrentUser } from "../CurrentUser";

interface CardProps {
  title: string
  description: string
  icon: IconProp
  to: string
}

function MenuCard(props: CardProps) {
  return (
    <Card className="pt-3" style={{ width: "14rem", height: "14rem" }}>
      <FontAwesomeIcon icon={props.icon} size="5x" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>{props.description}</Card.Text>
        <Link to={props.to} className="stretched-link"></Link>
      </Card.Body>
    </Card>
  );
}

function Home() {
  const { currentUser } = useCurrentUser();
  return (
    <Row>
      {currentUser?.isAdmin &&
        <Col md="auto" className="mr-auto mb-3">
          <MenuCard
            title="Admin"
            description="Administrative functions."
            icon={faToolbox}
            to="/admin"
          />
        </Col>
      }
      <Col md="auto" className="mr-auto mb-3">
        <MenuCard
          title="Profile"
          description="View and edit your account."
          icon={faUser}
          to="/account"
        />
      </Col>
      <Col md="auto" className="mr-auto mb-3">
        <MenuCard
          title="Reset Password"
          description="Reset your password."
          icon={faLockOpen}
          to="/reset-password"
        />
      </Col>
    </Row>
  );
}

export default Home;
