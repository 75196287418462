import { Spinner } from "react-bootstrap";
import { PropsWithChildren } from "react";

export function Loading() {
  return (
    <div className="text-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export function ShowLoading({ isLoading, children }: PropsWithChildren<{ isLoading: boolean }>) {
  return <>{isLoading ? <Loading /> : children}</>;
}

