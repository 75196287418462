interface ErrorResponse {
  msg: string;
}
export async function checkError(r: Response): Promise<string | null> {
  if (r.ok) {
    return null;
  }

  try {
    const resp: ErrorResponse = await r.json();
    return resp.msg;
  } catch {
    return r.statusText;
  }
}
