import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: window.SERVER_DATA.client_id,
    authority: window.SERVER_DATA.authority,
    redirectUri: window.SERVER_DATA.redirect_uri,
  },
  cache: { cacheLocation: "sessionStorage" },
};

export const loginRequest: RedirectRequest = {
  scopes: window.SERVER_DATA.scopes,
};
