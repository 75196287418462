import { Alert, Button } from "react-bootstrap";

function ErrorAlert(props: { msg: string }) {
  return (
    <Alert variant="danger">
      {props.msg}
      <br />
      <Button variant="primary">Sign out</Button>
    </Alert>
  );
}

export default ErrorAlert;
