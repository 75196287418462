import NavBar from "./components/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import ResetPassword from "./components/ResetPassword";
import { Container } from "react-bootstrap";
import ProfilePage from "./components/ProfilePage";
import Logout from "./components/Logout";
import ConfirmEmailPage from "./components/ConfirmEmailPage";
import { useCurrentUser } from "./CurrentUser";
import { ShowLoading } from "./components/Loading";
import ErrorPage from "./components/ErrorPage";

function App() {
  const { loading, error } = useCurrentUser();
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <main>
          <ShowLoading isLoading={loading && error === null}>
            <Container>
              {error !== null ? (
                <ErrorPage title="Error logging in" detail={error} />
              ) : (
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/account" element={<ProfilePage />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                </Routes>
              )}
            </Container>
          </ShowLoading>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
