import { Form } from "react-bootstrap";

export interface NewPasswordFormProps {
  password: string;
  setPassword: (password: string) => void;
  confirm: string;
  setConfirm: (password: string) => void;
  requirements: Record<string, (p: string) => boolean>;
  setValid?: (valid: boolean) => void;
}

function NewPasswordForm({ password, setPassword, confirm, setConfirm, requirements, setValid }: NewPasswordFormProps) {
  const passwordErrors = (): Array<string> => {
    const errors: Array<string> = [];
    Object.entries(requirements).forEach(([k, v]) => {
      !v(password) && errors.push(k);
    });
    return errors;
  };

  if (setValid !== undefined) {
    setValid(passwordErrors().length === 0 && password === confirm);
  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={passwordErrors().length === 0 ? "" : "is-invalid"}
        />
        <Form.Control.Feedback type="invalid">
          {passwordErrors().length > 0 && (
            <ul>
              {passwordErrors().map((e) => (
                <li>{e}</li>
              ))}
            </ul>
          )}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          type="password"
          required
          value={confirm}
          onChange={(e) => setConfirm(e.target.value)}
          className={confirm === password ? "" : "is-invalid"}
        />
        <Form.Control.Feedback type="invalid">Passwords must match</Form.Control.Feedback>
      </Form.Group>
    </>
  );
}

export default NewPasswordForm;
