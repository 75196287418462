import React from "react";
import { Button, Card } from "react-bootstrap";
import { useCurrentUser } from "../CurrentUser";

function ErrorPage({ title, detail }: { title?: string; detail?: string }) {
  const { isAuthenticated, signOut } = useCurrentUser();
  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    signOut();
  };
  return (
    <Card className="mb-4" key="danger">
      <Card.Header className="bg-danger text-white">{title}</Card.Header>
      <Card.Body>
        <p>{detail}</p>
        {isAuthenticated && <Button variant="primary" onClick={handleLogout}>Sign out</Button>}
      </Card.Body>
    </Card>
  );
}

export default ErrorPage;
