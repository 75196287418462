import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRightFromBracket, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useCurrentUser } from "../CurrentUser";

function NavBar() {
  const { currentUser, signIn, signOut, isAuthenticated } = useCurrentUser();

  const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    signOut();
  };
  const handleLogin = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    signIn();
  };
  return (
    <Navbar className="navbar navbar-expand-md navbar-dark bg-dark mb-3">
      <Container>
        <Navbar.Brand as={Link} to="/">
          My Account
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {isAuthenticated && currentUser !== undefined && (
              <NavDropdown
                className="dropdown-menu-end"
                title={
                  <>
                    <FontAwesomeIcon icon={faUser} /> {currentUser?.username}
                  </>
                }
              >
                <NavDropdown.Item onClick={handleLogout}>
                  <FontAwesomeIcon icon={faPowerOff} /> Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            )}
            {!isAuthenticated && (
              <Nav.Link onClick={handleLogin}>
                <FontAwesomeIcon icon={faRightFromBracket} /> Sign In
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default NavBar;
