function PasswordRequirements({ requirements }: { requirements: Record<string, (p: string) => boolean> }) {
  return (
    <>
      <p>Your new password must:</p>
      <ul>
        {Object.keys(requirements).map((r) => (
          <li>{r}</li>
        ))}
      </ul>
    </>
  );
}

export default PasswordRequirements;
