import { Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import { useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import SetPassword from "./SetPassword";
import { checkError } from "../utils/apiError";
import ErrorAlert from "./ErrorAlert";

function ResetPassword() {
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();

  const captchaRef = useRef<ReCAPTCHA>(null);

  const token = searchParams.get("token");

  if (token !== null) {
    return <SetPassword token={token} />;
  }

  const clearForm = () => {
    setEmail("");
    setUsername("");
    captchaRef.current?.reset();
    console.log(captchaRef.current?.getValue());
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    fetch("/api/public/request-reset", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        captcha: captcha,
        email: email.toLowerCase(),
        username: username.toLowerCase(),
      }),
    }).then((r: Response) => {
      setSubmitting(false);
      clearForm();
      checkError(r).then(setError);
    });
  };

  return (
    <>
      {error === null && <Alert variant="success">Check your email for further instructions.</Alert>}
      {error !== null && error !== undefined && <ErrorAlert msg={error} />}
      <h1>Reset your password</h1>
      <Col md={4}>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" required value={username} onChange={(e) => setUsername(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3">
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={window.SERVER_DATA.site_key}
              onChange={(v) => setCaptcha(v)}
              onExpired={() => setCaptcha(null)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={captcha === null || submitting === true}>
            {submitting && (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="visually-hidden">Submitting...</span>{" "}
              </>
            )}
            Submit
          </Button>{" "}
          {/* https://github.com/react-bootstrap/react-bootstrap/issues/6103 */}
          <Button variant="light" as={Link as any} to="/">
            Cancel
          </Button>
        </Form>
      </Col>
    </>
  );
}

export default ResetPassword;
