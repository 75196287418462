import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { PropsWithChildren, useEffect } from "react";
import { loginRequest } from "./authConfig";
import { checkError } from "./utils/apiError";

// https://stackoverflow.com/questions/64786653/current-user-with-react-context

export interface CurrentUser {
  username: string;
  name: string;
  surname: string;
  mail: string;
  groups: Array<string>;
  isAdmin: boolean;
}

export interface ICurrentUserContext {
  fetchAuth: (url: string, options: RequestInit) => Promise<Response>;
  loading: boolean;
  error: string | null;
  isAuthenticated: boolean;
  currentUser?: CurrentUser;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  clearProfile: () => void;
}

const CurrentUserContext = React.createContext<ICurrentUserContext | undefined>(undefined);

export function CurrentUserProvider({ children }: PropsWithChildren<{}>) {
  const { instance, inProgress, accounts } = useMsal();
  const [currentUser, setCurrentUser] = React.useState<CurrentUser>();
  const isAuthenticated = useIsAuthenticated();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  const acquireToken = async (): Promise<string> => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(loginRequest);
    }

    const req = { ...loginRequest, account: accounts[0] };
    try {
      const token = await instance.acquireTokenSilent(req);
      return token.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(req);
      }
      throw e;
    }
  };

  const fetchAuth = async (url: string, options: RequestInit): Promise<Response> => {
    const token = await acquireToken();
    const newOpts = { ...options };
    newOpts.headers = { ...newOpts.headers, Authorization: `Bearer ${token}` };
    return fetch(url, newOpts);
  };

  useEffect(() => {
    if (isAuthenticated && currentUser === undefined) {
      fetchAuth("/api/private/profile", { method: "GET" }).then((r) => {
        checkError(r).then((err) => {
          setError(err);
          if (err === null) {
            r.json().then((cu) => {
              setCurrentUser(cu);
              setLoading(false);
            });
          }
        });
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, currentUser]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        fetchAuth,
        loading,
        error,
        signIn: () => instance.loginRedirect(),
        signOut: () => instance.logoutRedirect(),
        clearProfile: () => setCurrentUser(undefined),
        isAuthenticated: isAuthenticated,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

export function useCurrentUser() {
  const ctx = React.useContext(CurrentUserContext);
  if (ctx === undefined) {
    throw new Error("useCurrentUser must be used within CurrentUserProvider");
  }
  return ctx;
}
